export const IsVaildItem = (item, allData) => {
  return (
    item && item.count > 0 && allData[item.catId] && allData[item.catId].items
  );
};

export const FindOptionItem = (optionKey, menuKey, menuOptions) => {
  let optionItem = null;
  menuOptions[menuKey].forEach((option) => {
    if (option.PKEY === optionKey) {
      optionItem = option;
      return;
    }
  });

  return optionItem;
};

export const CalculateItemTotal = (item, allData, menuOptions) => {
  let subtotal = 0;
  if (!IsVaildItem(item, allData)) return subtotal;

  let info = allData[item.catId].items.find(
    (element) => element.PKEY === item.menuKey,
  );
  if (info) {
    subtotal += parseFloat(info.PRICE) * item.count;
    if (item.optionsSelected) {
      item.optionsSelected.forEach((optionKey) => {
        let optionItem = FindOptionItem(optionKey, item.menuKey, menuOptions);
        if (optionItem) {
          subtotal += parseFloat(optionItem.PRICE) * item.count;
        }
      });
    }
  }

  return subtotal;
};

export const CalculateSubtotal = (itemsInCart, allData, menuOptions) => {
  let subtotal = 0;
  let keys = Object.keys(itemsInCart);
  keys.forEach((itemKey) => {
    let cartItem = itemsInCart[itemKey];
    subtotal += CalculateItemTotal(cartItem, allData, menuOptions);
  });

  return subtotal;
};
