import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsShowLogin,
  setIsShowCreateAccount,
  UpdateUserInfo,
  registerVerificationToken,
} from "../Slices/OrderedItemsSlice";
import AppConsts from "../App_Consts";
import { useCookies } from "react-cookie";

function Login() {
  const dispatch = useDispatch();
  const isShow = useSelector((state) => {
    return state.ordredItem.isShowLogin;
  });

  const [cookies, setCookie] = useCookies(["name"]);

  const handleCancel = () => {
    dispatch(setIsShowLogin(false));
  };

  const handleCreateAcount = () => {
    dispatch(setIsShowLogin(false));
    dispatch(setIsShowCreateAccount(true));
  };

  const handleSignIn = async () => {
    var userId = document.getElementById("userId");
    var pw = document.getElementById("password");
    let targetUrl =
      AppConsts.API_URL_BASE +
      "/Auth/SignIn?userId=" +
      userId.value +
      "&password=" +
      pw.value;
    let respone = await fetch(targetUrl);
    if (respone.ok) {
      let jsonData = await respone.json();

      setCookie("unserInformation", {
        userId: userId.value,
        userKey: jsonData.userIDKey,
        contact: jsonData.contact,
        fName: jsonData.fName,
        lName: jsonData.lName,
      });

      setCookie("verification", {
        verificationToken: "",
        contact: jsonData.contact,
        isVerified: jsonData.isVerified,
      });

      dispatch(
        UpdateUserInfo({
          userId: userId.value,
          userKey: jsonData.userIDKey,
          contact: jsonData.contact,
          fName: jsonData.fName,
          lName: jsonData.lName,
        }),
      );
      dispatch(
        registerVerificationToken({
          verificationToken: "",
          contact: jsonData.contact,
          isVerified: jsonData.isVerified,
        }),
      );

      dispatch(setIsShowLogin(false));
    } else {
      var signinMsg = document.getElementById("signinMsg");
      signinMsg.innerText = "Email address or Password is incorrect.";
    }
  };

  return (
    <Modal size="md" show={isShow}>
      <Modal.Header>
        <h4>User Sign In</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="md-form mb-3">
          <label className="form-label">Email address:</label>
          <span className="ms-2 text-danger" id="signinMsg"></span>
          <div className="input-group">
            <span className="input-group-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path>
              </svg>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Enter email"
              aria-label="Enter email"
              aria-describedby="basic-addon1"
              id="userId"
            />
          </div>
        </div>
        <div className="md-form mb-4">
          <label className="form-label">Password:</label>
          <div className="input-group">
            <span className="input-group-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-file-lock2"
                viewBox="0 0 16 16"
              >
                <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"></path>
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              aria-label="Enter password"
              aria-describedby="basic-addon1"
              id="password"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={handleCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSignIn}>
          Sign In
        </button>
        <button className="btn btn-link" onClick={handleCreateAcount}>
          Create an account
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Login;
