import Modal from "react-bootstrap/Modal";
import { setIsShowOption, resetOptions } from "../Slices/OrderedItemsSlice";
import { useSelector, useDispatch } from "react-redux";
import ModalOptionBody from "./ModalOptionBody";

function ModalOptions() {
  const isShowOptions = useSelector((state) => {
    return state.ordredItem.isShowOption;
  });
  const optionMenuKey = useSelector((state) => {
    return state.ordredItem.optionMenuKey;
  });

  const dispatch = useDispatch();

  const HideOptionModal = () => {
    dispatch(
      setIsShowOption({
        isShowCartItems: false,
        optionMenuKey: optionMenuKey,
      }),
    );
  };

  const ApplyOptions = () => {
    HideOptionModal();
  };

  const resetAndHide = () => {
    dispatch(resetOptions({ menuKey: optionMenuKey }));
    HideOptionModal();
  };

  return (
    <Modal size="lg" show={isShowOptions} onHide={HideOptionModal}>
      <Modal.Body>
        <ModalOptionBody />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={resetAndHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={ApplyOptions}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOptions;
