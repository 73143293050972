import CupBap from "./images/CupBap - Copy.png";
import KimBap from "./images/KimBap - Copy.png";
import Sandwich from "./images/Sandwich - Copy.png";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
import { useState } from "react";

function Home(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="home_Wraper" style={{ backgroundColor: "black" }}>
      <div className="elem-header" style={{ textAlign: "center" }}>
        No.1 Korean fusion food
      </div>
      <div className="slider">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img src={Sandwich} className="w-10" alt="..."></img>
            <Carousel.Caption>
              <h3>Sandwich</h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={CupBap} className="w-10" alt="..."></img>
            <Carousel.Caption>
              <h3>CupBap</h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={KimBap} className="w-10" alt="..."></img>
            <Carousel.Caption>
              <h3>KimBap</h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Home;
