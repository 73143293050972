function InputForm(props) {
  const { inputId, inputLabel, inputValue, updator } = props;
  const aria_label = "Enter " + inputLabel;
  const inputVal = inputValue;

  return (
    <div className="md-form mb-3">
      <label className="form-label float-lg-start">{inputLabel}:</label>
      <div className="input-group">
        <input
          type="text"
          className="form-control me-2"
          placeholder={inputLabel}
          aria-label={aria_label}
          aria-describedby="basic-addon1"
          id={inputId}
          defaultValue={inputVal}
          onBlur={updator}
        />
      </div>
    </div>
  );
}

export default InputForm;
