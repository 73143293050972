import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantityInCart,
  decrementQuantityInCart,
  generateOrderedKey,
} from "../Slices/OrderedItemsSlice";
// import { useCookies } from "react-cookie";

import {
  CalculateSubtotal,
  IsVaildItem,
  FindOptionItem,
} from "./CheckoutUtility";
import { auto } from "@popperjs/core";

function CheckoutBody() {
  const itemsInCart = useSelector((state) => {
    return state.ordredItem.itemsInCart;
  });
  const allData = useSelector((state) => {
    return state.ordredItem.data;
  });
  const menuOptions = useSelector((state) => {
    return state.ordredItem.menuOptions;
  });

  const dispatch = useDispatch();

  var subTotal = CalculateSubtotal(itemsInCart, allData, menuOptions);
  var tax = subTotal * 0.13;
  var grandTotal = subTotal * 1.13;
  var orderedItems = [];
  for (let itemKey in itemsInCart) {
    let item = itemsInCart[itemKey];
    if (IsVaildItem(item, allData)) {
      orderedItems.push(item);
    }
  }

  if (!orderedItems || orderedItems.length === 0) {
    return <div className="CheckoutBody NoItems">No Items in cart</div>;
  }
  let bodyMaxHeight = window.innerHeight * 0.7;
  return (
    <div className="CheckoutBody">
      <div
        className="contentsBody"
        style={{ maxHeight: bodyMaxHeight, overflow: auto }}
      >
        {orderedItems.map((item) => {
          var menuItemInfo = allData[item.catId].items.find(
            (element) => element.PKEY === item.menuKey,
          );
          let optionSelected = [];
          if (item.optionsSelected !== undefined) {
            optionSelected = item.optionsSelected;
          }
          var price = parseFloat(menuItemInfo.PRICE) * item.count;
          return (
            <div className="mb-3 container-fluid" key={item.menuKey}>
              <div className="row">
                <h4 className="cartMenuName col-6">{menuItemInfo.NAME}</h4>
                <div className="btn-group cartBtn col-3 ControlBtnGrp">
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(
                        decrementQuantityInCart(
                          generateOrderedKey(menuItemInfo.PKEY, optionSelected),
                        ),
                      )
                    }
                    className="btnLeft btnCountControl btn btn-info"
                  >
                    -
                  </button>
                  <span className="CountInfo">{item.count}</span>
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(
                        incrementQuantityInCart(
                          generateOrderedKey(menuItemInfo.PKEY, optionSelected),
                        ),
                      )
                    }
                    className="btnRight btnCountControl btn btn-info"
                  >
                    +
                  </button>
                </div>
                <h4 className="cartPrice col-3">${price.toFixed(2)}</h4>
              </div>
              <div className="OptionsSelected row">
                {optionSelected.map((selecteOptionKey) => {
                  let optionItem = FindOptionItem(
                    selecteOptionKey,
                    menuItemInfo.PKEY,
                    menuOptions,
                  );
                  if (optionItem) {
                    let optionPrice = optionItem.PRICE * item.count;
                    return (
                      <div className="row">
                        <span className="col-9">- {optionItem.NAME}</span>
                        <span className="col-2 px-5">${optionPrice}</span>
                      </div>
                    );
                  }
                  return <div></div>;
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="CheckOutInfoContainer">
        <div>
          <span className="col-6">Subtotal:</span>
          <span className="col-6">${subTotal.toFixed(2)}</span>
        </div>
        <div>
          <span className="col-6">Tax:</span>
          <span className="col-6">${tax.toFixed(2)}</span>
        </div>
        <div>
          <span className="col-6">Grand Total:</span>
          <span className="col-6">${grandTotal.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutBody;
