import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useState, useEffect } from "react";
import loobapbapIamge from "./images/LooBapBap_logo1-4.jpg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CategoryMenu from "./Categories/CategoryMenu";
import { Provider } from "react-redux";
import CheckoutPayment from "./Checkout/CheckoutPayment";
import About from "./About";
import OrderedItemStore from "../src/Slices/OrderedItemStore";
import UserInfo from "./Users/UserInfo";
import { CookiesProvider } from "react-cookie";
import Home from "./Home";
import Menu from "./Menu";
import Contact from "./Contact";

function App() {
  const [categories, setCategory] = useState([]);

  useEffect(() => {
    loadCategory();
  }, []);
  var menuId = 1;
  const loadCategory = async () => {
    let homeItem = `{"PKEY":"-1","CAT_ID":"-1","CAT_DESC":"Home"}`;
    let menuItem = `{"PKEY":"-2","CAT_ID":"-2","CAT_DESC":"Menu"}`;
    let aboutItem = `{"PKEY":"-3","CAT_ID":"-3","CAT_DESC":"About"}`;
    let contactItem = `{"PKEY":"-4","CAT_ID":"-4","CAT_DESC":"Contact"}`;
    let newData = [homeItem, menuItem, aboutItem, contactItem];
    setCategory(newData);
  };

  const ExtractPathFromUrlPath = (urlPath) => {
    var result = urlPath.substring(1);
    for (let idx = 1; idx < urlPath.length; idx++) {
      var charUrl = urlPath[idx];
      if (charUrl === "/") {
        result = urlPath.substring(1, idx);
      }
    }
    return result;
  };

  return (
    <CookiesProvider>
      <Router>
        <Provider store={OrderedItemStore}>
          <div className="App" style={{ width: "98%", maxHeight: "95%" }}>
            <div className="row">
              <div className="col-sm-2 col-lg-2">
                <img
                  src={loobapbapIamge}
                  alt="LooBapBap Logo"
                  width="100%"
                  style={{ maxWidth: "180px" }}
                />
              </div>
              <div className="col-sm-2 col-lg-7"></div>
              <div className="col-sm-8 col-lg-3 float-right">
                <UserInfo />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3 col-lg-2 CatContainer">
                {categories.map((item) => {
                  let jsonItem = JSON.parse(item);
                  return (
                    <CategoryMenu
                      key={jsonItem.CAT_ID}
                      CatName={jsonItem.CAT_DESC}
                      Id={jsonItem.CAT_ID}
                    />
                  );
                })}
              </div>
              <div className="col-sm-9 col-lg-10">
                <Routes>
                  <Route exact path="/Menu/:catId" element={<Menu />} />
                  <Route exact path="/Checkout" element={<CheckoutPayment />} />
                  <Route exact path="/Home" element={<Home />} />
                  <Route
                    exact
                    path="/Menu"
                    element={<Menu defaultCatId={menuId} />}
                  />
                  <Route exact path="/About" element={<About />} />
                  <Route exact path="/Contact" element={<Contact />} />
                  <Route exact path="/" element={<Home />} />
                </Routes>
              </div>
            </div>
            <div className="row copyRight_Wrap">
              <div className="contact">(519) 883-4747</div>
              <div className="location">
                280 Lester St Unit 104, Waterloo, ON N2L 0G2
              </div>
              <div className="copyRight">
                © 2024 LOOBAPBAP INC. All Rights Reserved.
              </div>
            </div>
          </div>
          <script type="javascript"></script>
        </Provider>
      </Router>
    </CookiesProvider>
  );
}

export default App;
