const AppConsts = {};
AppConsts.KEY_UNDEFINED = -1;
AppConsts.API_URL_BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/API"
    : "https://www.loobapbap.ca:8884/API";
AppConsts.STORE_ID =
  "uc1kQSwW6Ykxrf17sNPrRoV47NjWMEsKLAgJYMg5v1SGsTj0o1zQyRN/oMCSPN5K6nphX5SvUe+sTXWEwoAb+ujqYyMUe+1d3hYAZRaJyHA=";
AppConsts.SquareSource =
  process.env.NODE_ENV === "development"
    ? "https://sandbox.web.squarecdn.com/v1/square.js"
    : "https://web.squarecdn.com/v1/square.js";
export default AppConsts;
