import { useSelector, useDispatch } from "react-redux";
import { updateCheckedOptionItems } from "../Slices/OrderedItemsSlice";
import { right } from "@popperjs/core";
// import { useCookies } from "react-cookie";

function ModalOptionBody(props) {
  const menuKey = useSelector((state) => {
    return Number(state.ordredItem.optionMenuKey);
  });
  const dispatch = useDispatch();

  const optionItems = useSelector((state) => {
    return state.ordredItem.menuOptions[menuKey];
  });
  const optionChecked = useSelector((state) => {
    return state.ordredItem.menuOptionsChecked;
  });

  const OnClickOption = (optionKey) => {
    let checkOption = document.getElementById("Check_" + optionKey);
    dispatch(
      updateCheckedOptionItems({
        menuKey,
        optionKey,
        checkedState: checkOption.checked,
      }),
    );
  };

  const isChecked = (optionKey) => {
    let result =
      optionChecked[menuKey] &&
      optionChecked[menuKey].length > 0 &&
      optionChecked[menuKey].indexOf(optionKey) >= 0;
    return result;
  };

  const generateInput = (optionKey, item, price) => {
    var isCheckedOption = isChecked(optionKey);
    var IsSoldOut = item.IsSoldOut === "True";
    let result = <div></div>;
    var soldOutTag = "";
    if (IsSoldOut) {
      soldOutTag = (
        <span>
          <span style={{ marginRight: "5px", marginLeft: "5px" }}>-</span>
          <span style={{ color: "red" }}>Sold Out</span>
        </span>
      );
    }
    result = (
      <label className="form-check-label">
        {price}
        {item.NAME} {soldOutTag}
        <input
          className="form-check-input"
          type="checkbox"
          id={"Check_" + item.PKEY}
          checked={isCheckedOption}
          disabled={IsSoldOut}
          onClick={() => OnClickOption(item.PKEY)}
          onChange={() => OnClickOption(item.PKEY)}
        />
      </label>
    );
    // }
    return result;
  };

  return (
    <div className="OptionBody">
      {optionItems.map((item) => {
        if (item.PKEY === -1 || !item.IsActive) return <div>{item.NAME}</div>;
        var price = "";
        if (item.PRICE !== "0") {
          price = <span className="m-2 font-weight-bold">${item.PRICE}</span>;
        }
        var optionCheckBox = generateInput(item.PKEY, item, price);
        return (
          <div
            className={"Option_" + item.PKEY + " form-check"}
            key={item.PKEY}
          >
            {optionCheckBox}
          </div>
        );
      })}
    </div>
  );
}

export default ModalOptionBody;
