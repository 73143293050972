import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

export const GetVerificationInfo = () => {
  var verificationInfo = useSelector((state) => {
    return state.ordredItem.verificationInfo;
  });

  const [cookies] = useCookies(["verification"]);
  if (
    verificationInfo.contact.length === 0 &&
    !verificationInfo.isVerified &&
    cookies.verification &&
    cookies.verification.isVerified
  ) {
    verificationInfo = cookies.verification;
  }
  return verificationInfo;
};

export const GetVerificationInfoFromCookie = () => {
  const [cookies] = useCookies(["verification"]);
  return cookies.verification;
};

export const GetUserInfo = () => {
  const userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  return userInfo;
};

export const GetUserInfoFromCookie = () => {
  const [cookies] = useCookies(["unserInformation"]);
  return cookies.unserInformation;
};
