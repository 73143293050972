import AppConsts from "../App_Consts";

const Logger = {};
Logger.Log = (key, contents) => {
  if (process.env.NODE_ENV === "development") {
    console.log(key, contents);
  }
};

export default Logger;
