import { left } from "@popperjs/core";

function About(props) {
  return (
    <div className="about_Wraper">
      <div className="elem-header" style={{ textAlign: left }}>
        ABOUT US
      </div>
      <div className="elem-content col-6">
        <span>
          <strong style={{ marginRight: 5 }}>LooBapBap</strong> is a popular
          Waterloo-based Korean fusion food restaurant. We have been satisfying
          our customers with Korean style sandwichs and baps since 2021. If you
          are ready to satisfy your own stomach, come and try our food! Rest
          assured, you will enjoy every bite.
        </span>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="elem-wrap elem-content">
            <div className="elem-header">DELICIOUS FOOD</div>
            <div>
              Taste the diverse flavours of South Korea. Once you taste it, you
              will want to come back for more.
            </div>
          </div>
          <div className="elem-wrap elem-content">
            <div className="elem-header">FRESH INGREDIENTS</div>
            <div>
              Need a meal on the go? Welcome - you're at the right place!
            </div>
          </div>
          <div className="elem-wrap elem-content">
            <div className="elem-header">FAST SERVICE</div>
            <div>
              Our ingredients are freshly prepared every morning to serve you
              with the highest quality food!
            </div>
          </div>
          <div className="elem-wrap elem-content">
            <div className="elem-header">FRIENDLY STAFF</div>
            <div>
              Your satisfaction is our utmost priority. We welcome any feedback
              that will help us learn and improve!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
