import { useSelector, useDispatch } from "react-redux";
import cartIamge from "../images/icons8-shopping-cart-64.png";
import ModalItemsInCart from "./ModalItemsInCart";
import React, { useState, useEffect } from "react";
import { setIsShowCart } from "../Slices/OrderedItemsSlice";

function CartInfo() {
  const orderedItemsCount = useSelector((state) => {
    let totalCount = 0;
    if (state.ordredItem.itemsInCart) {
      for (let idx in state.ordredItem.itemsInCart) {
        let item = state.ordredItem.itemsInCart[idx];
        if (item && item.count > 0) {
          totalCount += item.count;
        }
      }
    }

    return totalCount;
  });

  const dispatch = useDispatch();

  const showCart = useSelector((state) => {
    return state.ordredItem.isShowCartItems;
  });

  const handleClickOnCartItems = () => {};

  return (
    <div>
      <ModalItemsInCart />
      <span>
        <img className="Cart" src={cartIamge} alt="Cart Logo" />
        <button
          className="badge text-bg-danger M3Badge"
          type="button"
          onClick={() => {
            dispatch(setIsShowCart(true));
          }}
        >
          {orderedItemsCount}
        </button>
      </span>
    </div>
  );
}

export default CartInfo;
