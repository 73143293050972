import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useSelector, useDispatch } from "react-redux";
import { setIsShowCreateAccount } from "../Slices/OrderedItemsSlice";
import AppConsts from "../App_Consts";
import Logger from "../Utilities/Logger";

function CreateAccount() {
  const dispatch = useDispatch();
  const isShow = useSelector((state) => {
    return state.ordredItem.isShowCreateAccount;
  });

  const handleCancel = () => {
    dispatch(setIsShowCreateAccount(false));
  };

  const handleSubmit = async () => {
    var firstPw = document.getElementById("password");
    var confirmPw = document.getElementById("confirmPassword");
    if (firstPw.value !== confirmPw.value) {
      firstPw.classList.add("is-invalid");
      confirmPw.classList.add("is-invalid");
      return;
    }

    var submitBtn = document.getElementById("submitBtn");
    submitBtn.disabled = true;
    var userId = document.getElementById("emailAddress");
    let targetUrl =
      AppConsts.API_URL_BASE +
      "/Auth/CreateUser?userId=" +
      userId.value +
      "&password=" +
      firstPw.value;
    let respone = await fetch(targetUrl);
    let jsonData = await respone.json();

    var msg = document.getElementById("emailMsg");
    msg.innerText = jsonData.message;
  };

  const ValidatePw = () => {
    var firstPw = document.getElementById("password");
    var msg = document.getElementById("passwordMsg");
    if (firstPw.value.length < 8) {
      msg.innerText = "Minimum length is 8.";
    }
    return;
  };

  return (
    <Modal size="md" show={isShow}>
      <Modal.Header>
        <h4 className="text-center">Create an account</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="md-form mb-3">
          <label className="form-label">Email address:</label>
          <span id="emailMsg" className="errorMsg"></span>
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path>
              </svg>
            </span>
            <input
              type="text"
              className="form-control"
              id="emailAddress"
              placeholder="Enter email"
              aria-label="Enter email"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="md-form mb-4">
          <label className="form-label">
            Password:
            <span id="passwordMsg" className="errorMsg"></span>
          </label>
          <div className="input-group">
            <span className="input-group-text" id="firstPw">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-file-lock2"
                viewBox="0 0 16 16"
              >
                <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"></path>
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
              aria-label="Enter password"
              aria-describedby="basic-addon1"
              onBlur={ValidatePw}
            />
          </div>
        </div>
        <div className="md-form mb-4">
          <label className="form-label">Confirm Password:</label>
          <div className="input-group">
            <span className="input-group-text" id="confirmPw">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-file-lock2"
                viewBox="0 0 16 16"
              >
                <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"></path>
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              placeholder="Enter password"
              aria-label="Enter password"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          id="submitBtn"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAccount;
