import AppConsts from "../App_Consts";
import {
  registerVerificationToken,
  UpdateUserInfo,
} from "../Slices/OrderedItemsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

function ContactWithVerification(props) {
  const dispatch = useDispatch();
  const {
    inputId,
    inputLabel,
    isVerificationRequired,
    onVerified,
    contactNumber,
  } = props;
  const aria_label = "Enter " + inputLabel;
  var verificationInfo = useSelector((state) => {
    return state.ordredItem.verificationInfo;
  });
  const userInfo = useSelector((state) => {
    return state.ordredItem.userInfo;
  });

  const [cookies, setCookie] = useCookies(["verification"]);
  if (
    verificationInfo.contact.length === 0 &&
    !verificationInfo.isVerified &&
    cookies.verification &&
    cookies.verification.isVerified
  ) {
    verificationInfo = cookies.verification;
  }

  console.log("verificationInfo verification:", verificationInfo);
  var msgText = "";
  if (isVerificationRequired)
    msgText = "Contact Number must be verified to proceed placing order";
  if (verificationInfo.isVerified) msgText = "Verified";

  var btnLabel = "Send Code";
  var verificationInput = "";
  if (verificationInfo.contact.length > 0) {
    verificationInput = (
      <input
        type="text"
        className="form-control col-lg-1"
        placeholder="Enter code"
        aria-label="Enter code"
        aria-describedby="basic-addon1"
        id="verificationCode"
      />
    );
    btnLabel = "Verify Code";
  }

  const SendCode = async () => {
    var contactElement = document.getElementById(inputId);
    const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
    let targetUrl = `${AppConsts.API_URL_BASE}/Auth/SendCode?storeId=${encodedStoreId}&contactNumber=${contactElement.value}&userKey=${userInfo.userKey}`;
    const sendCodeResponse = await fetch(targetUrl);
    let jsonData = await sendCodeResponse.json();
    var msg = document.getElementById("labelText");
    if (sendCodeResponse.ok) {
      dispatch(
        registerVerificationToken({
          verificationToken: jsonData.verificationToken,
          contact: contactElement.value,
        })
      );

      msg.innerText = "Verification code has been sent";
    } else {
      msg.innerText = jsonData.message;
    }
  };

  const VerifyCode = async () => {
    var verificationCodeElem = document.getElementById("verificationCode");
    var contactElement = document.getElementById(inputId);
    const encodedStoreId = encodeURIComponent(AppConsts.STORE_ID);
    let targetUrl = `${AppConsts.API_URL_BASE}/Auth/VerifyCode?storeId=${encodedStoreId}&contactNumber=${contactElement.value}&verificationCode=${verificationCodeElem.value}&userKey=${userInfo.userKey}`;
    const verifyCodeResponse = await fetch(targetUrl);
    if (verifyCodeResponse.ok) {
      dispatch(
        registerVerificationToken({
          verificationToken: verificationInfo.verificationToken,
          contact: verificationInfo.contact,
          isVerified: true,
        })
      );
      dispatch(
        UpdateUserInfo({
          userId: userInfo.userId,
          userKey: userInfo.userKey,
          contact: verificationInfo.contact,
          fName: userInfo.fName,
          lName: userInfo.lName,
        })
      );
      setCookie("verification", {
        verificationToken: verificationInfo.verificationToken,
        contact: verificationInfo.contact,
        isVerified: true,
      });
      if (onVerified) {
        onVerified();
      }
    } else {
      let jsonData = await verifyCodeResponse.json();
      var msg = document.getElementById("labelText");
      msg.innerText = jsonData.message;
    }
  };

  var verifyBtn = "";
  if (isVerificationRequired) {
    if (verificationInfo.verificationToken.length == 0) {
      verifyBtn = (
        <button type="button" className="btn btn-primary" onClick={SendCode}>
          {btnLabel}
        </button>
      );
    } else {
      verifyBtn = (
        <button type="button" className="btn btn-primary" onClick={VerifyCode}>
          {btnLabel}
        </button>
      );
    }
  }

  var contactInput = "";
  if (verificationInfo.isVerified) {
    contactInput = <label>{verificationInfo.contact}</label>;
    verificationInput = "";
    verifyBtn = "";

    var msg = document.getElementById("labelText");
    if (msg) {
      msg.innerText = "Verified";
    }
  } else {
    contactInput = (
      <input
        type="text"
        className="form-control"
        placeholder={inputLabel}
        aria-label={aria_label}
        aria-describedby="basic-addon1"
        id={inputId}
        defaultValue={contactNumber}
      />
    );
  }

  return (
    <div className="md-form mb-3">
      <label className="form-label float-lg-start">
        {inputLabel}:
        <span id="labelText" className="ms-2 text-danger">
          {msgText}
        </span>
      </label>
      <div className="input-group">
        {contactInput}
        {verificationInput}
        {verifyBtn}
      </div>
    </div>
  );
}

export default ContactWithVerification;
